import type { Calendar } from "@prisma/client"
import { useState } from "react"
import { TeachHowToBookOnGoogleModal } from "./Configuration/TeachHowToBookOnGoogleModal"
import useTranslateFunction from "@hooks/useTranslateFunction"
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline"
import { useRouter } from "next/router"
import { usePrompt } from "@hooks/usePrompt"
import { mixpanel } from "lib/mixpanel"
import { useUser } from "@hooks/useUser"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "ui_components/shadcn/ui/dropdown-menu"

export function CalendarList({
  calendars = [],
  numberOfEventsThisMonthPerCalendar,
  onAddCalendar,
}: {
  calendars?: Calendar[]
  onAddCalendar: () => void
  numberOfEventsThisMonthPerCalendar?: Record<string, number>
}) {
  const { tWithComponents } = useTranslateFunction()
  const [teachingHowToBookOnCalendar, setTeachingHowToBookOnCalendar] =
    useState("")
  const { t } = useTranslateFunction()
  const { openPrompt } = usePrompt()
  const { user } = useUser()

  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold text-gray-900">
            {t("CalendarList_Calendarios")}
          </h1>

          {/* <p className="mt-2 text-sm text-gray-700">
            {t("CalendarList_Tienes dos opciones para guardar las citas:")}
          </p>
          <div className="hide-if-mobile-app mt-2 text-sm text-gray-700 ">
            <p className="prose text-sm">
              {tWithComponents(
                `CalendarList_1. Puedes utilizar el <0>calendario de Confirmafy</0>.`,
                [<Link href={"/app/calendar"} />]
              )}
            </p>
            <p className="prose text-sm">
              {tWithComponents(
                `CalendarList_2. O puedes utilizar <0>calendarios de Google.</0>`,
                [<Link href={"/integracion-de-google-calendar-con-whatsapp"} />]
              )}
            </p>
          </div> */}
        </div>
        <div className="mt-4 flex justify-between sm:ml-16 sm:mt-0 sm:flex-none">
          {/* <a
            target="_blank"
            href="https://calendar.google.com"
            className=" mr-2 inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mr-2 h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
              />
            </svg>
            Abrir Google Calendar
          </a> */}
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={onAddCalendar}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mr-1 h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
            {t("CalendarList_Agregar Calendario")}
          </button>
          {user?.locale === "es" && (
            <button
              onClick={() => {
                mixpanel.track("Video tutorial modal open")

                openPrompt({
                  title: "En 2 min te mostramos cómo funciona Confirmafy",
                  description: (
                    <div className="flex justify-center">
                      <video
                        onPlay={() => mixpanel.track("Video tutorial play")}
                        onEnded={() => mixpanel.track("Video tutorial ended")}
                        src="/app/confirmafy-in-a-nutshell-tiny.mp4"
                        controls
                        style={{ height: "70vh" }}
                      ></video>
                    </div>
                  ),
                  onConfirm() {
                    console.log("confirm")
                  },
                })
              }}
              className="ml-2 inline-flex items-center rounded-md bg-gray-200 px-3 py-2 text-sm font-medium  leading-4 text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="mr-1 h-4 w-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z"
                />
              </svg>
              Tutorial
            </button>
          )}
        </div>
      </div>

      <div className="-mx-4 mt-10 md:mx-0 md:rounded-lg">
        {calendars.length === 0 ? (
          <div className="flex flex-col items-center justify-center rounded border bg-white py-14 text-center">
            <div>{t("CalendarList_No tienes calendarios agregados")}</div>
            <div>
              <button
                type="button"
                className="mt-4 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                onClick={onAddCalendar}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mr-1 h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v12m6-6H6"
                  />
                </svg>
                {t("CalendarList_ Agregar Calendario")}
              </button>
            </div>
          </div>
        ) : (
          <Table
            calendars={calendars}
            numberOfEventsThisMonthPerCalendar={
              numberOfEventsThisMonthPerCalendar
            }
            setTeachingHowToBookOnCalendar={setTeachingHowToBookOnCalendar}
          ></Table>
        )}
      </div>

      <TeachHowToBookOnGoogleModal
        open={teachingHowToBookOnCalendar != ""}
        calendarName={teachingHowToBookOnCalendar}
        onClose={() => setTeachingHowToBookOnCalendar("")}
      ></TeachHowToBookOnGoogleModal>
    </div>
  )
}

function Table({
  calendars,
  numberOfEventsThisMonthPerCalendar,
  setTeachingHowToBookOnCalendar,
}: {
  calendars: Calendar[]
  numberOfEventsThisMonthPerCalendar?: Record<string, number>
  setTeachingHowToBookOnCalendar: (calendar: string) => void
}) {
  const { t } = useTranslateFunction()

  return (
    <div className="mt-8 rounded px-4 ring-opacity-5 sm:px-0 md:border">
      <div className=" hidden grid-cols-4 border-b bg-gray-50 md:grid">
        <div className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
          {t("CalendarList_Calendario")}
        </div>
        <div className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
          {t("CalendarList_Estado")}
        </div>
        <div className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
          {t("CalendarList_Confirmaciones enviadas")}
        </div>
        <div>
          <span className="sr-only">{t("CalendarList_Acciones")}</span>
        </div>
      </div>
      <div className="">
        {calendars
          .sort((calA, calB) => calA.summary.localeCompare(calB.summary))
          .map((calendar) => (
            <div
              key={calendar.id}
              className="mt-4 grid grid-cols-1 rounded border-b  bg-white py-4 shadow md:mt-0 md:grid-cols-4 md:items-center md:rounded-none md:py-0 md:shadow-none"
            >
              <div className="  whitespace-nowrap px-5 py-2 pr-3 text-sm md:py-4 md:pl-6">
                <div className="flex items-center overflow-hidden  whitespace-normal">
                  {calendar.source === "google" && (
                    <span className="mr-2 inline-block w-6 flex-shrink-0">
                      <img src="/app/google-calendar-icon.png" alt="" />
                    </span>
                  )}
                  {calendar.source === "native" && (
                    <span className="mr-2 inline-block w-6 flex-shrink-0">
                      <img src="/logo.png" alt="" />
                    </span>
                  )}
                  <div className="font-medium text-gray-900 ">
                    {calendar.summary}
                  </div>
                </div>
              </div>
              <div className="whitespace-nowrap px-5  py-2 text-sm text-gray-500 md:py-4">
                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                  {t("CalendarList_Enviando confirmaciones")}
                </span>
              </div>
              <div className="hidden whitespace-nowrap px-5  py-2 text-sm text-gray-500 md:block md:py-4">
                <div className="text-gray-900">
                  <span className="text-lg">
                    {numberOfEventsThisMonthPerCalendar &&
                    numberOfEventsThisMonthPerCalendar![calendar.id]
                      ? numberOfEventsThisMonthPerCalendar![calendar.id]
                      : 0}
                  </span>
                  <span className="ml-1 text-gray-500">
                    {t("CalendarList_este mes")}
                  </span>
                </div>
              </div>
              <div className="relative mt-4 flex items-end whitespace-nowrap px-5 py-2 pr-4 text-right text-sm font-medium sm:pr-6 md:mt-0 md:py-4">
                <CalendarActions
                  calendar={calendar}
                  setTeachingHowToBookOnCalendar={
                    setTeachingHowToBookOnCalendar
                  }
                ></CalendarActions>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

function CalendarActions({ calendar, setTeachingHowToBookOnCalendar }: any) {
  const [active, setActive] = useState(false)
  const { t } = useTranslateFunction()
  const router = useRouter()

  return (
    <div className="flex w-full justify-end">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button className="ml-6 flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            {t("CalendarActions_Acciones")}
            <EllipsisVerticalIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem asChild>
            <button
              className="flex w-full items-center gap-x-2 rounded-md px-2 py-2 text-sm text-gray-900 hover:bg-gray-100"
              onClick={() => {
                router.push(`/app/calendar/${calendar.id}`)
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="NoFill h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                />
              </svg>
              Configurar
            </button>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <button
              className="flex w-full items-center gap-x-2 rounded-md px-2 py-2 text-sm text-gray-900 hover:bg-gray-100"
              onClick={() => {
                if (calendar.source === "native") {
                  router.push(`/app/calendar`)
                } else {
                  setTeachingHowToBookOnCalendar(calendar.summary)
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="NoFill h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Crear cita
            </button>
          </DropdownMenuItem>
          {calendar.source === "google" && (
            <DropdownMenuItem asChild>
              <button
                className="flex items-center gap-x-2 rounded-md px-2 py-2 text-sm text-gray-900 hover:bg-gray-100"
                onClick={() => {
                  router.push(
                    `/app/calendar/${calendar.id}/future-appointments`
                  )
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="NoFill h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3"
                  />
                </svg>
                Ver mensajes programados
              </button>
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
