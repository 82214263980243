import useTranslateFunction from "@hooks/useTranslateFunction"
import { useUserCountry } from "@hooks/useUserCountry"
import { KeyboardEvent } from "react"

import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input"
import "react-phone-number-input/style.css"

export function CustomPhoneInput({ value, onChange, placeholder }: any) {
  const { t } = useTranslateFunction()
  const { country } = useUserCountry()

  function customIsValidPhoneNumber(value: string) {
    if (value?.startsWith("+593")) {
      // Ecuadorian phone numbers have 13 digits including the country code
      // isPossiblePhoneNumber returns true for phone numbers with 12 digits so we need to check for 13
      return isPossiblePhoneNumber(value) && value.length === 13
    }

    return isPossiblePhoneNumber(value)
  }

  return (
    <>
      <PhoneInput
        placeholder={
          placeholder || t("customPhoneInput_Escribe tu número de WhatsApp")
        }
        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
          e.key === "Enter" && e.preventDefault()
        }}
        value={value}
        onChange={onChange}
        countryCallingCodeEditable={false}
        // @ts-ignore
        defaultCountry={country || "MX"}
        displayInitialValueAsLocalNumber
        withCountryCallingCode={false}
        error={
          value
            ? customIsValidPhoneNumber(value)
              ? undefined
              : "Invalid phone number"
            : "Phone number required"
        }
      />
      {value && !customIsValidPhoneNumber(value) && (
        <div className="mt-2 text-xs text-red-500">
          {t("customPhoneInput_Número inválido")}
        </div>
      )}
    </>
  )
}
