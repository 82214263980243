import useTranslateFunction from "@hooks/useTranslateFunction"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogClose,
} from "ui_components/shadcn/ui/dialog"
import { useUser } from "hooks"
import mobileApp, { useMobileAppHelper } from "lib/mobileApp"

export function TeachHowToBookOnGoogleModal({
  calendarName,
  onClose,
  open,
}: {
  calendarName: string
  onClose: () => void
  open: boolean
}) {
  const { t, tWithComponents } = useTranslateFunction()
  const { user } = useUser()
  const { isMobileApp } = useMobileAppHelper()
  let primaryAction: any = {
    content: t("howToBookOnGoogle_Ir a Google Calendar"),
    url: "https://calendar.google.com/",
    external: true,
  }

  if (isMobileApp) {
    primaryAction = {
      content: t("howToBookOnGoogle_Ir a Google Calendar"),
      onAction: () => {
        mobileApp.openGoogleCalendarApp()
      },
    }
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t("howToBookOnGoogle_Cómo crear citas en este calendario")}
          </DialogTitle>
        </DialogHeader>
        <div className="max-h-[80vh] overflow-y-auto">
          <DialogDescription>
            <div className="prose">
              <p>
                {t(
                  "howToBookOnGoogle_En Google Calendar crea un evento con la siguiente información:"
                )}
              </p>
              <p>
                {tWithComponents(
                  'howToBookOnGoogle_1. En el título puedes escribir lo que quieras, <0>pero debes dejar el número de teléfono del cliente.</0> Por ejemplo: "Cita con Ricardo Macario +15146326713',
                  [<b></b>]
                )}
              </p>
              <p>
                {t(
                  "howToBookOnGoogle_2. Asegúrate de seleccionar tu calendario: "
                )}{" "}
                <b>
                  {user?.email === calendarName
                    ? "Primario"
                    : `${calendarName}`}
                </b>
              </p>
            </div>
            <img
              className=" h-110"
              src="/app/how-to-create-appointment-on-google.jpg"
              alt=""
            />
          </DialogDescription>
        </div>
        <DialogClose />
      </DialogContent>
    </Dialog>
  )
}
