import { getTranslatedStringForLocale } from "lib/i18n"
import { Spinner } from "./Spinner"

export function WhatsAppTemplatePreview({
  heading = "",
  body = "",
  date = "",
  confirmationQuestionToBeAsked = "",
  showActions = false,
  locale = "es",
  forceShowDateWillComeFromCalendar = false,
  headerImageUrl = null,
  hideDateIsExample = false,
  onConfirm = () => {},
  onCancel = () => {},
  loadingConfirm = false,
  loadingCancel = false,
}) {
  const bodyWithLineBreaks = body.replace(/\n/g, "<br />")

  return (
    <div
      style={{ backgroundColor: "#e5ddd5", position: "relative" }}
      className="flex w-full px-2 py-4"
    >
      <div className="w-full">
        <div
          style={{
            background: "url(/whatsapp-background.png)",
            backgroundSize: "366.5px 666px",
            height: "100%",
            left: 0,
            opacity: 0.06,
            position: "absolute",
            top: 0,
            width: "100%",
          }}
        ></div>
        <div className="mx-auto w-full max-w-xs break-words">
          <div className="relative z-10  mb-1  rounded bg-white p-2 ">
            <div
              style={{
                background: "url('cachito-mensaje.png') 50% 50% no-repeat",
                backgroundSize: "contain",
                content: "",
                height: "19px",
                left: "-10px",
                position: "absolute",
                top: 0,
                width: "12px",
              }}
            ></div>

            {headerImageUrl && (
              <div>
                <img
                  src={headerImageUrl}
                  alt=""
                  className="mb-2 h-16 w-full rounded-t object-contain"
                />
              </div>
            )}

            <h3 className="mb-2 font-bold">{heading}</h3>
            <p
              className="mb-2 text-sm"
              dangerouslySetInnerHTML={{ __html: bodyWithLineBreaks }}
            ></p>
            {date && <p className=" text-sm first-letter:capitalize">{date}</p>}
            {(date || forceShowDateWillComeFromCalendar) &&
              hideDateIsExample === false && (
                <p className="">
                  <span className=" text-xs text-gray-400">
                    {getTranslatedStringForLocale(
                      {
                        es: "(Fecha de ejemplo)",
                        en: "(Example date)",
                        pt: "(Exemplo de data)",
                        it: "(Esempio di data)",
                        fr: "(Exemple de date)",
                      },
                      locale
                    )}
                  </span>
                </p>
              )}
            {showActions && (
              <p className="my-2  text-sm">{confirmationQuestionToBeAsked}</p>
            )}
          </div>
          {showActions && (
            <div className="relative z-10 flex pb-2">
              <button
                type="button"
                className="mr-1 flex flex-grow items-center justify-center rounded bg-white py-1  text-center text-blue-400 "
                onClick={onCancel}
              >
                {getTranslatedStringForLocale(
                  {
                    es: "No",
                    en: "No",
                    pt: "Não",
                    it: "No",
                    fr: "Non",
                  },
                  locale
                )}
                {loadingCancel && <Spinner className="ml-1 " size={12} />}
              </button>
              <button
                type="button"
                className="mr-1 flex flex-grow items-center justify-center rounded bg-white py-1  text-center text-blue-400 "
                onClick={onConfirm}
              >
                {getTranslatedStringForLocale(
                  {
                    es: "Sí",
                    en: "Yes",
                    pt: "Sim",
                    it: "Sì",
                    fr: "Oui",
                  },
                  locale
                )}
                {loadingConfirm && <Spinner className="ml-1 " size={12} />}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
